import { StylesManager, FunctionFactory, Serializer, setLicenseKey } from "survey-core"
import dateFormat from 'dateformat'
import store from '@/store'
import { setLocale} from '@/i18n'
import _ from 'lodash'

const SURVEY_OPTIONS = {
  hideContinue: false
}

const surveyJsUtil = {
  init(options) {
    options = Object.assign({
    }, options || {})

    surveyJsUtil.adjustProperties()
    surveyJsUtil.applyStyle(options)
    surveyJsUtil.registerFunctions()

    setLicenseKey("ZmMxYzMzNGItZWYwZi00ZWMzLTk1OTEtZThjNjNlY2YyNWM0OzE9MjAyNS0wNi0yNiwyPTIwMjUtMDYtMjY=")
  },

  adjustProperties() {
    // Set default value for "Clear invisible value" property of 'none'
    // https://github.com/tema-q/woenenn-vue-client/issues/992
    Serializer.findProperty('survey', 'clearInvisibleValues').defaultValue = 'none'
  },

  addTextMarkdown(survey) {
    // allow html in the survey title
    survey.onTextMarkdown.add((survey, options) => {
      let str = (options.text || '').trim()

      // apply html if there is an opening and closing tag in the string
      if (str.match(/^\s*<.+>[\s\S]*<\/.+>\s*$/)) {
        options.html = str
      }
    })
  },

  applyStyle(options) {
    options = Object.assign({
      theme: 'defaultV2' // 'legacy', "defaultV2", "modern"
    }, options || {})

    let defaultThemeColors = StylesManager
      .ThemeColors["default"]
    defaultThemeColors["$main-color"] = "#666"
    defaultThemeColors["$main-hover-color"] = "#666"
    defaultThemeColors["$text-color"] = "rgba(0, 0, 0, 0.87)"
    defaultThemeColors["$header-color"] = "rgba(0, 0, 0, 0.87)"

    switch (options.theme) {
      case "legacy":
        StylesManager.applyTheme()
        break;
      case "modern":
        StylesManager.applyTheme("modern")
        break;  
      default:
        StylesManager.applyTheme("defaultV2")
        break;
    }
  },

  /*formatField(val, type, format) {
    switch (type) {
      case 'date':
        val = (val && Date.parse(val)) ? dateFormat(new Date(val), format || "yyyy-mm-dd") : null
        break
      case 'datetime':
        val = (val && Date.parse(val)) ? dateFormat(new Date(val), format || "yyyy-mm-dd HH:MM") : null
        break
      case 'number':
        val = format ? Math.floor(val * format) / format : val
        break
    }
    return val
  },*/

  getSurveyOption(survey, optionName) {
    return survey && Object.assign({}, SURVEY_OPTIONS, survey.__OPTIONS || {})[optionName]
  },

  registerFunctions() {
    const lookup = async function(params) {
      let collectionName = params[0]
      let sourceKey = params[1]
      let value = params[2]
      let targetKey = params[3]

      let surveyJsMapping = await store.dispatch('getSurveyJsMapping', {
        collectionName: collectionName
      })

      let entry = surveyJsMapping.find(entry => {
        return _.get(entry, sourceKey) === value
      })

      let returnResult = entry && _.get(entry, targetKey)
      this.returnResult(returnResult)
      return returnResult
    }

     /*
    * Issue #619
    */
     const get = function(params) {
      let what = params[0]
      let returnResult

      switch (what) {
        case 'user_email':
          returnResult = store.state.user.email
          break
        case 'user_role':
          returnResult = store.state.roleName
          break
        case 'survey_mode':
          // returns 'survey' or 'woenenn'
          returnResult = 'survey'
          break
        case 'cati':
          returnResult = store.state.cati
          break
        default:
          throw new Error(`unknown string "${what}" for custom SurveyJs function get()`)
      }

      return returnResult
    }

    const changeLanguage = function(params) {
      let locale = params[0]

      this.survey.locale = locale

      return locale
    }

    const setSurveyOption = function(params) {
      let surveyOptionKey = params[0]
      let surveyOptionValue = params[1]
      this.survey.__OPTIONS = this.survey.__OPTIONS || Object.assign({}, SURVEY_OPTIONS)
      this.survey.__OPTIONS[surveyOptionKey] = surveyOptionValue
    }

    // from Issue #951
    const string2number = function(params) {
      if (!params || params.length < 1) {
        return
      }

      let number = Number(params[0])

      if (isNaN(number)) {
        return  
      }
      
      return number
    }

    // from Issue #951
    const abs = function(params) {
      if (!params || params.length < 1) {
        return
      }

      let number = Number(params[0])

      if (isNaN(number)) {
        return
      }

      let absNumber = Math.abs(number)
      
      return absNumber
    }
    
    FunctionFactory.Instance.register("get", get)
    FunctionFactory.Instance.register("lookup", lookup, true)
    FunctionFactory.Instance.register("changeLanguage", changeLanguage)
    FunctionFactory.Instance.register("setSurveyOption", setSurveyOption)
    FunctionFactory.Instance.register("string2number", string2number)
    FunctionFactory.Instance.register("abs", abs)
  }
}

export default surveyJsUtil
